import { atom, selector, useRecoilValue } from "recoil";

export const SUPPORTED_ETH_NETWORKS = JSON.parse(
  process.env.REACT_APP_SUPPORTED_ETH_NETWORKS
);

export const INFURA_PUBLIC_ID = process.env.REACT_APP_INFURA_PUBLIC_ID;

export const defaultConfigId = Number(process.env.REACT_APP_DEFAULT_CONFIG_ID);

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseUrl: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const isDevelopment = () => process.env.NODE_ENV === "development";

const liveEnvs = ["production", "staging"];
export const isLive = () =>
  process.env.NODE_ENV !== "development" &&
  liveEnvs.includes(process.env.REACT_APP_ENVIRONMENT);

export const isProduction = () =>
  process.env.REACT_APP_ENVIRONMENT === "production";

const localConfig = {
  environment: "local",
  apiUrl: "http://localhost:8080",

  contracts: {
    fxc: "0x3112498305b2bf167cf46292e11145b3e64f179f",
    staking: "0x2204681640b86d0863086fd232745572824b3800",
    amp: "0x7b615ceeaf35cf25b1878b53d5192f8689635b07",
    flexaCollateralManager: "0x092126e5b5b5d1ec51e622932de63961cfa54177",
  },

  firebase: {
    projectId: "test-project-id",
    apiKey: "AIzaSyBE2oZHK-4cin54R5YUwon6e25iAj8Ncjc",
    authDomain: "localhost",
    databaseUrl: "http://localhost:8025",
    appId: "1:266998343857:web:1d26c2ff5a67e51dc2da90",
  },

  apps: [
    {
      appId: "gemini",
      slug: "gemini",
      color: "#00DBF9",
      name: "Gemini",
      logo: "/assets/img/app-gemini.png",
      partition:
        "0xcccccccc1337bda8573d0000092126e5b5b5d1ec51e622932de63961cfa54177",
      poolType: "app",
    },
    {
      appId: "spedn",
      slug: "spedn",
      color: "#ff19b4",
      name: "SPEDN",
      logo: "/assets/img/app-spedn.png",
      partition:
        "0xcccccccc1337542966cb0000092126e5b5b5d1ec51e622932de63961cfa54177",
      poolType: "app",
    },
    {
      appId: "lightning-transformer",
      slug: "lightning-transformer",
      color: "#BA35D1",
      name: "Lightning",
      logo: "/assets/img/transformer-lightning.png",
      partition:
        "0xccccccccfc37542966cb0000092126e5b5b5d1ec51e622932de63961cfa54177",
      poolType: "transformer",
    },
  ],

  accountPollTimeout: 10000,
};

const sepoliaConfig = {
  environment: "staging",
  apiUrl: "https://api.capacity.staging.flexa.network",

  contracts: {
    fxc: "0xbd90e82a0bc31d1a732d8c474566907960198868",
    staking: "0xf2ef99d78c9f90a1b2b0f6420df7fb9eb4accc8b",
    amp: "0x89e1954982a9fc451d7998d68233882ccecf1431",
    flexaCollateralManager: "0x0a44f73fe440f49580f715e0c931c0216f758773",
  },

  firebase: {
    projectId: "flexa-capacity-app-staging",
    apiKey: "AIzaSyARPThmkXDkEQY4ADaAmo8EOrSQCxXcjVk",
    authDomain: "flexa-capacity-app-production.firebaseapp.com",
    databaseUrl: "https://flexa-capacity-app-staging.firebaseio.com",
    appId: "1:266998343857:web:1d26c2ff5a67e51dc2da90",
  },

  apps: [
    {
      appId: "gemini",
      slug: "gemini",
      color: "#00DBF9",
      name: "Gemini",
      logo: "/assets/img/app-gemini.png",
      partition:
        "0xcccccccc951b7d808d20441b0a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "app",
    },
    {
      appId: "spedn",
      slug: "spedn",
      color: "#ff19b4",
      name: "SPEDN",
      logo: "/assets/img/app-spedn.png",
      partition:
        "0xcccccccca8a7ec9d93e24ae10a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "app",
    },
    {
      appId: "nighthawk",
      slug: "nighthawk",
      color: "#000000",
      name: "Nighthawk",
      logo: "/assets/img/app-nighthawk-wallet.png",
      partition:
        "0xcccccccc7ee6a8733d014dde0a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "app",
    },
    {
      appId: "zashi",
      slug: "zashi",
      color: "#272525",
      name: "Zashi",
      logo: "/assets/img/app-zashi-wallet.png",
      partition:
        "0xcccccccc6ed3233ce9af48da0a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "app",
    },
    {
      appId: "base",
      slug: "base",
      color: "#0052ff",
      name: "Base",
      logo: "/assets/img/transformers/transformer-base.png",
      partition:
        "0xcccccccc756fde7595704f040a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
    {
      appId: "test01",
      slug: "test01",
      color: "#f7931a", // btc
      name: "test1",
      logo: "/assets/img/transformers/transformer-bitcoin.png",
      partition:
        "0xccccccccc009f104dd394ceb0a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
    {
      appId: "test02",
      slug: "test02",
      color: "#00C58a", // bch
      name: "test2",
      logo: "/assets/img/transformers/transformer-bitcoin-cash.png",
      partition:
        "0xcccccccc41689a480f3648e00a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
    {
      appId: "test03",
      slug: "test03",
      color: "#0033ad", // cardano
      name: "test3",
      logo: "/assets/img/transformers/transformer-cardano.png",
      partition:
        "0xcccccccc2d36af2da29a4d440a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
    {
      appId: "test04",
      slug: "test04",
      color: "#35d07f", // celo
      name: "test4",
      logo: "/assets/img/transformers/transformer-celo.png",
      partition:
        "0xcccccccc9667192bd2374f380a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
    {
      appId: "test05",
      slug: "test05",
      color: "#ba9f33", // doge
      name: "test5",
      logo: "/assets/img/transformers/transformer-dogecoin.png",
      partition:
        "0xcccccccc6b434e0b1b8942090a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
    {
      appId: "test06",
      slug: "test06",
      color: "#1c5cff", // eth
      name: "test6",
      logo: "/assets/img/transformers/transformer-ethereum.png",
      partition:
        "0xccccccccc4d8955409394ebe0a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
    {
      appId: "lightning",
      slug: "lightning",
      color: "#BA35D1",
      name: "Lightning",
      logo: "/assets/img/transformer-lightning.png",
      partition:
        "0xcccccccc656fa682324c46540a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
    {
      appId: "test07",
      slug: "test07",
      color: "#345d9d", // ltc
      name: "test7",
      logo: "/assets/img/transformers/transformer-litecoin.png",
      partition:
        "0xcccccccc33e0d910c2d34ef00a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
    {
      appId: "test08",
      slug: "test08",
      color: "#8247e5", // polygon
      name: "test8",
      logo: "/assets/img/transformers/transformer-polygon.png",
      partition:
        "0xcccccccc5f2cc1a0aea94ba30a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
    {
      appId: "test09",
      slug: "test09",
      color: "#8247e5", // sol
      name: "test9",
      logo: "/assets/img/transformers/transformer-solana.png",
      partition:
        "0xccccccccc29546e79de14d7a0a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
    {
      appId: "test10",
      slug: "test10",
      color: "#2c7df7", // tezos
      name: "test10",
      logo: "/assets/img/transformers/transformer-tezos.png",
      partition:
        "0xcccccccc6c1dea739b234ad40a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
    {
      appId: "test11",
      slug: "test11",
      color: "#f4b728", // zch
      name: "test11",
      logo: "/assets/img/transformers/transformer-zcash.png",
      partition:
        "0xccccccccb032203d9e3249640a44f73fe440f49580f715e0c931c0216f758773",
      poolType: "transformer",
    },
  ],

  accountPollTimeout: 10000,
};

const mainnetConfig = {
  environment: "production",
  apiUrl: "https://api.capacity.production.flexa.network",

  autoConnect: true,

  contracts: {
    fxc: "0x4a57e687b9126435a9b19e4a802113e266adebde",
    staking: "0x12f208476f64de6e6f933e55069ba9596d818e08",
    amp: "0xff20817765cb7f73d4bde2e66e067e58d11095c2",
    flexaCollateralManager: "0x706d7f8b3445d8dfc790c524e3990ef014e7c578",
  },

  firebase: {
    projectId: "flexa-capacity-app-production",
    apiKey: "AIzaSyBE2oZHK-4cin54R5YUwon6e25iAj8Ncjc",
    authDomain: "flexa-capacity-app-staging.firebaseapp.com",
    databaseUrl: "https://flexa-capacity-app-production.firebaseio.com",
    appId: "1:734412218710:web:7355abd47bf5967b88f6f7",
  },

  apps: [
    {
      appId: "gemini",
      slug: "gemini",
      color: "#00DBF9",
      name: "Gemini",
      logo: "/assets/img/app-gemini.png",
      partition:
        "0xcccccccc2862b8cb21caedb8706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "app",
    },
    {
      appId: "spedn",
      slug: "spedn",
      color: "#ff19b4",
      name: "SPEDN",
      logo: "/assets/img/app-spedn.png",
      partition:
        "0xccccccccd6bbe6bf0c2471ba706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "app",
    },
    {
      appId: "nighthawk",
      slug: "nighthawk",
      color: "#000000",
      name: "Nighthawk",
      logo: "/assets/img/app-nighthawk-wallet.png",
      partition:
        "0xcccccccca4560ce3f67c4c16706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "app",
    },
    {
      appId: "zashi",
      slug: "zashi",
      color: "#272525",
      name: "Zashi",
      logo: "/assets/img/app-zashi-wallet.png",
      partition:
        "0xcccccccc807d297dbf73441c706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "app",
    },
    {
      appId: "base",
      slug: "base",
      color: "#0052ff",
      name: "Base",
      logo: "/assets/img/transformers/transformer-base.png",
      partition:
        "0xcccccccc3ff751f2e7c64f16706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
    {
      appId: "bitcoin",
      slug: "bitcoin",
      color: "#f7931a", // btc
      name: "Bitcoin",
      logo: "/assets/img/transformers/transformer-bitcoin.png",
      partition:
        "0xcccccccc9e74da6b6cf54fc8706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
    {
      appId: "bitcoin-cash",
      slug: "bitcoin-cash",
      color: "#00C58a", // bch
      name: "Bitcoin Cash",
      logo: "/assets/img/transformers/transformer-bitcoin-cash.png",
      partition:
        "0xcccccccc3110fc85041345c2706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
    {
      appId: "cardano",
      slug: "cardano",
      color: "#0033ad", // cardano
      name: "Cardano",
      logo: "/assets/img/transformers/transformer-cardano.png",
      partition:
        "0xcccccccc17e127f74077433c706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
    {
      appId: "celo",
      slug: "celo",
      color: "#35d07f", // celo
      name: "Celo",
      logo: "/assets/img/transformers/transformer-celo.png",
      partition:
        "0xccccccccac3507679b14453a706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
    {
      appId: "dogecoin",
      slug: "dogecoin",
      color: "#ba9f33", // doge
      name: "Dogecoin",
      logo: "/assets/img/transformers/transformer-dogecoin.png",
      partition:
        "0xcccccccc81dbde757c384900706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
    {
      appId: "ethereum",
      slug: "ethereum",
      color: "#1c5cff", // eth
      name: "Ethereum",
      logo: "/assets/img/transformers/transformer-ethereum.png",
      partition:
        "0xcccccccc327b7f0c08214931706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
    {
      appId: "lightning",
      slug: "lightning",
      color: "#BA35D1",
      name: "Lightning",
      logo: "/assets/img/transformer-lightning.png",
      partition:
        "0xcccccccc7a0208a97d8ac263706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
    {
      appId: "litecoin",
      slug: "litecoin",
      color: "#345d9d", // ltc
      name: "Litecoin",
      logo: "/assets/img/transformers/transformer-litecoin.png",
      partition:
        "0xcccccccc186b8f18e6ee4b42706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
    {
      appId: "polygon",
      slug: "polygon",
      color: "#8247e5", // polygon
      name: "Polygon",
      logo: "/assets/img/transformers/transformer-polygon.png",
      partition:
        "0xcccccccc01ef57163c6d4d34706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
    {
      appId: "solana",
      slug: "solana",
      color: "#8247e5", // sol
      name: "Solana",
      logo: "/assets/img/transformers/transformer-solana.png",
      partition:
        "0xcccccccc22315143f82c475f706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
    {
      appId: "tezos",
      slug: "tezos",
      color: "#2c7df7", // tezos
      name: "Tezos",
      logo: "/assets/img/transformers/transformer-tezos.png",
      partition:
        "0xcccccccc5504c217e344479d706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
    {
      appId: "zcash",
      slug: "zcash",
      color: "#f4b728", // zch
      name: "Zcash",
      logo: "/assets/img/transformers/transformer-zcash.png",
      partition:
        "0xcccccccc5eb25f6982e04b21706d7f8b3445d8dfc790c524e3990ef014e7c578",
      poolType: "transformer",
    },
  ],

  accountPollTimeout: 5000,
};

export const configs = {
  1: mainnetConfig,
  11155111: sepoliaConfig,
  1337: localConfig,
};

export const chainIdAtom = atom({
  key: "chainId",
  default: defaultConfigId,
});

export const autoConnectLocalStorageSelector = selector({
  key: "autoConnectLocalStorage",
  get: () => JSON.parse(localStorage?.getItem("@flexa/capacity/autoConnect")),
  set: ({ set }, newValue) => {
    localStorage?.setItem(
      "@flexa/capacity/autoConnect",
      JSON.stringify(newValue)
    );
    // eslint-disable-next-line @typescript-eslint/no-use-before-define -- recursive definition
    set(autoConnectAtom, newValue);
  },
});

export const autoConnectAtom = atom({
  key: "autoConnect",
  default: autoConnectLocalStorageSelector,
});

export const configSelector = selector({
  key: "ConfigSelector",
  get: ({ get }) => {
    const chainId = get(chainIdAtom);
    const config = configs[chainId] || configs[defaultConfigId];
    if (!config) {
      throw new Error("Invalid default config ID");
    }

    const autoConnect = get(autoConnectAtom);

    return {
      ...config,
      autoConnect,
    };
  },
});

export const useConfig = () => useRecoilValue(configSelector);
